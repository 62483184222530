<ng-container *ngIf="value$ | async as value">
  <ng-container *ngIf="value.valueParts; else valueFormatted">
    <ng-container *ngIf="value.valueParts as valueParts">
      <app-tooltip
        [text]="value.valueFormatted + ' ' + currency"
        [tooltipPosition]="tooltipPosition"
        [usePopover]="tooltipUsePopover"
        [tooltipClass]="tooltipClass"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
        <ng-container>{{ valueParts.integerPart }}{{ valueParts.decimalSeparator }}</ng-container>
        <ng-container *ngIf="valueParts.leadingZerosCount && valueParts.leadingZerosCount - 1"
          >0<sub>{{ valueParts.leadingZerosCount - 1 }}</sub></ng-container
        >
        <ng-container>{{ valueParts.fractionalPart }}</ng-container>
        <ng-container *ngIf="!hideCurrency">&nbsp;{{ currency }}</ng-container>
      </app-tooltip>
    </ng-container>
  </ng-container>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>

  <ng-template #valueFormatted>
    <ng-container *ngTemplateOutlet="content"></ng-container>
    {{ value.valueFormatted }}<ng-container *ngIf="!hideCurrency">&nbsp;{{ currency }}</ng-container>
  </ng-template>
</ng-container>
