import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { BalanceWithCurrencyEquivalents } from '@app/shared/store/balances/balances.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { CurrencyDto } from '@app/generated/models/currency-dto';
import { MAX_VISIBLE_CURRENCIES } from '@app/shared/const/currencies';

const DESKTOP_BREAKPOINT = 768;

@Component({
  selector: 'app-context-trade-currency-modal',
  templateUrl: './context-trade-currency-modal.component.html',
  styleUrls: ['./context-trade-currency-modal.component.scss'],
})
export class ContextTradeCurrencyModalComponent implements OnInit, OnDestroy {
  @Input() modalId = '';
  @Input() modalData: {
    balances: Observable<BalanceWithCurrencyEquivalents[]>;
    onlyAvailableBalances?: boolean;
  } = {
    balances: of([]),
    onlyAvailableBalances: true,
  };
  @Input() modalActions: {
    onCurrencySelect?: (currency: CurrencyDto) => void;
  } = {};

  headers = [
    'trading-platform.modal.balances.headers.currency',
    'trading-platform.modal.balances.headers.total-balance',
    'trading-platform.modal.balances.headers.in-open-orders',
    'trading-platform.modal.balances.headers.available',
  ];

  isMobileViewport = false;
  tradingSuspensionFTM = false;

  isBalancesListLimitedSubject = new BehaviorSubject<boolean>(true);
  searchTermSubject = new BehaviorSubject<string>('');
  balances$: Observable<BalanceWithCurrencyEquivalents[]> = of([]);
  balancesLimited$: Observable<BalanceWithCurrencyEquivalents[]> = of([]);

  private unsubscribe$ = new Subject<void>();

  constructor(private guiParams: GuiParams, private translateService: TranslateService) {
    this.guiParams.guiParams$.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.tradingSuspensionFTM = params.features.includes('frontend_api-qt-trading_disabled-ftm');
    });
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      this.setMobileViewport(window.innerWidth);
    }

    this.balances$ = this.modalData.balances.pipe(
      takeUntil(this.unsubscribe$),
      map((balances) => balances.filter(this.currenciesFilter.bind(this))),
    );
    this.balancesLimited$ = combineLatest([
      this.modalData.balances,
      this.isBalancesListLimitedSubject,
      this.searchTermSubject,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([balances, isCurrenciesListLimited, searchTerm]) => {
        const currenciesFiltered = balances.filter(this.currenciesFilter.bind(this));
        const currenciesLimited =
          isCurrenciesListLimited && !searchTerm
            ? currenciesFiltered.slice(0, MAX_VISIBLE_CURRENCIES)
            : currenciesFiltered;

        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return currenciesLimited.filter((currency) => {
          return (
            currency.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            this.getCurrencyDisplayName(currency.name)
              // next two lines are for diacritics insensitive search
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .includes(lowerCaseSearchTerm) ||
            this.getCurrencyDisplayName(currency.name).toLowerCase().includes(lowerCaseSearchTerm)
          );
        });
      }),
      map((currenciesFiltered) => {
        return currenciesFiltered.sort((a, b) => {
          return (
            (b.name === 'CZK' ? 1 : 0) - (a.name === 'CZK' ? 1 : 0) ||
            (b.name === 'EUR' ? 1 : 0) - (a.name === 'EUR' ? 1 : 0) ||
            b.fiatEquivalentsTotal.CZK - a.fiatEquivalentsTotal.CZK
          );
        });
      }),
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setMobileViewport(event.target.innerWidth);
  }

  trackByItemId(_index: number, currency: any) {
    return `row-${currency.name}`;
  }

  onSearchTermChange(searchTerm: string) {
    this.searchTermSubject.next(searchTerm);
  }

  getCurrencyDisplayName(currencyCode: string): string {
    const key = `crypto.${currencyCode}`;
    const translation = this.translateService.instant(key);
    return key === translation ? currencyCode : translation;
  }

  showAllBalances() {
    this.isBalancesListLimitedSubject.next(false);
  }

  private currenciesFilter(currency: BalanceWithCurrencyEquivalents) {
    return !this.modalData.onlyAvailableBalances ? true : currency.available > 0 || currency.inOpenOrders > 0;
  }

  private setMobileViewport(width: number) {
    this.isMobileViewport = width < DESKTOP_BREAKPOINT;
  }
}
