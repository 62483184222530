<button
  [type]="type"
  (click)="!waiting && action.emit()"
  [disabled]="disabled || waiting"
  class="cm-button {{ appearance }}"
  [ngClass]="{
    'cm-button-xs': size === 'xs',
    'cm-button-small': size === 'small',
    'cm-button-primary': facet === 'primary',
    'cm-button-tertiary': facet === 'tertiary',
    'cm-button-secondary': facet === 'secondary',
    'cm-button-secondary-danger': facet === 'secondary-danger',
    'cm-button-danger': facet === 'danger',
    'cm-button-link': facet === 'link',
    'cm-button-secondary-link': facet === 'secondary-link',
    'cm-button-secondary-link-danger': facet === 'secondary-link-danger',
    'cm-button-inactive': inactive,
    '!w-full': isFullWidth
   }"
>
  <ng-container *ngIf="!waiting; else spinner">
    <span class="icon-left">
      <ng-content select="[button-icon-left]"></ng-content>
    </span>
    <ng-container *ngIf="label">
      <span class="cm-button-label">{{ label | translate }}</span>
    </ng-container>
    <ng-content></ng-content>
    <span class="icon-right">
      <ng-content select="[button-icon-right]"></ng-content>
    </span>
  </ng-container>

  <ng-template #spinner>
    <ng-container *ngIf="progressLabel ? progressLabel : label as label">
      <span class="cm-button-label">{{ label | translate }}</span>
    </ng-container>
    <div class="cm-button-loader"></div>
  </ng-template>
</button>
