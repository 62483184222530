import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-action-button',
  standalone: true,
  templateUrl: './action-button.html',
  styleUrls: ['./action-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActionButtonComponent),
      multi: true,
    },
  ],
  host: {
    '[class]': 'isFullWidth ? "!w-full" : ""',
  },
  imports: [CommonModule, TranslateModule],
})
export class ActionButtonComponent {
  @Input() label!: string;
  @Input() disabled?: boolean;
  @Input() inactive?: boolean;
  @Input() waiting?: boolean;
  @Input() progressLabel?: string;
  @Input() type?: 'button' | 'submit' | 'reset' = 'button';
  @Input() facet?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'secondary-danger'
    | 'link'
    | 'secondary-link'
    | 'secondary-link-danger'
    | 'form';
  @Input() size?: 'xs' | 'small' | 'medium' = 'medium';
  @Input() isFullWidth: boolean = false;
  // TODO: DTP-6208 - remove appearance once margin is removed from button style
  @Input() appearance?: string;
  @Output() action: EventEmitter<void> = new EventEmitter<void>();
}
