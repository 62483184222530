<app-base-modal
  [id]="modalId"
  [title]="'trading-platform.modal.balances.title' | translate"
  [size]="'large'"
  [isBodyZeroSpacing]="true"
>
  <ng-container modal-header>
    <app-search
      [searchPlaceholder]="'trading-platform.modal.balances.search' | translate"
      (searchTermChange)="onSearchTermChange($event)"
    ></app-search>
  </ng-container>
  <app-base-modal-body>
    <ng-container *ngIf="balancesLimited$ | async as currencies">
      <ng-container *ngIf="currencies.length; else noData">
        <table class="table-auto w-full">
          <thead>
            <tr class="currencies-table-row">
              <ng-container *ngIf="isMobileViewport; else headerDesktop">
                <th class="currencies-table-cell">
                  {{ headers[0] | translate }}
                </th>
                <ng-container *ngIf="headers.slice(1, headers.length) as restHeaders">
                  <th class="currencies-table-cell" [colSpan]="restHeaders.length">
                    <ng-container *ngFor="let header of restHeaders.reverse(); let isLast = last">
                      <span class="inline-flex items-center gap-x-6px">
                        {{ header | translate }}
                      </span>
                      <br *ngIf="!isLast" />
                    </ng-container>
                  </th>
                </ng-container>
              </ng-container>
              <ng-template #headerDesktop>
                <th *ngFor="let header of headers" class="currencies-table-cell">
                  {{ header | translate }}
                </th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let currency of currencies; trackBy: trackByItemId"
              class="currencies-table-row"
              [ngClass]="{
                clickable: modalActions.onCurrencySelect
              }"
              (click)="modalActions.onCurrencySelect && modalActions.onCurrencySelect(currency)"
            >
              <td class="currencies-table-cell">
                <div class="flex items-center gap-x-8px md:gap-x-20px">
                  <app-currency-icon class="h-8 w-8" [currencyName]="currency.name" [alt]="currency.displayName" />
                  <div class="flex flex-col gap-y-2px">
                    <div class="font-bold">
                      {{ getCurrencyDisplayName(currency.name) }}
                    </div>
                    <div class="text-dimmed text-sm">{{ currency.name }}</div>
                    <div class="hidden md:flex md:flex-wrap md:gap-[8px]">
                      <ng-container
                        *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"
                      ></ng-container>
                      <ng-container *ngTemplateOutlet="tradingSuspension; context: { $implicit: currency }"></ng-container>
                    </div>
                  </div>
                </div>
              </td>
              <td *ngIf="isMobileViewport" class="currencies-table-cell">
                <div class="flex flex-col gap-4px">
                  <div class="font-bold">
                    {{ currency.available | appCurrencyFloor : currency.name | async }} {{ currency.name }}
                    <div class="flex flex-wrap gap-[8px]">
                      <ng-container
                        *ngTemplateOutlet="onlyBuySellInfo; context: { $implicit: currency }"
                      ></ng-container>
                      <ng-container *ngTemplateOutlet="tradingSuspension; context: { $implicit: currency }"></ng-container>
                    </div>
                  </div>
                  <div class="text-dimmed text-sm md:hidden">
                    {{ currency.inOpenOrders | appCurrencyFloor : currency.name | async }} {{ currency.name }}
                  </div>
                  <div class="text-dimmed text-sm md:hidden">
                    {{ currency.totalBalance | appCurrencyFloor : currency.name | async }} {{ currency.name }}
                  </div>
                </div>
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell text-sm">
                {{ currency.totalBalance | appCurrencyFloor : currency.name | async }} {{ currency.name }}
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell text-sm">
                {{ currency.inOpenOrders | appCurrencyFloor : currency.name | async }} {{ currency.name }}
              </td>
              <td *ngIf="!isMobileViewport" class="currencies-table-cell font-bold">
                {{ currency.available | appCurrencyFloor : currency.name | async }} {{ currency.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>

    <ng-template #noData>
      <div class="no-data">
        {{ (searchTermSubject.value ? 'currencies.not-found' : 'shared.common.no-data') | translate }}
      </div>
    </ng-template>

    <ng-template #onlyBuySellInfo let-currency>
      <app-dropdown
        *ngIf="!currency.depositActive || !currency.withdrawalActive"
        [popoverPosition]="'topStart'"
        [popoverClass]="'md:min-w-[382px]'"
      >
        <button ribbon dropdown-trigger>
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.only-buy-sell' | translate }}
        </button>
        <app-message
          [type]="'info'"
          [showMessage]="true"
          [compact]="true"
          [title]="'currencies.blocked-deposit-withdrawal.title' | translate"
          [message]="'currencies.blocked-deposit-withdrawal.message' | translate"
        >
          <app-action-button
            [facet]="'secondary'"
            [size]="'small'"
            [label]="'shared.common.close' | translate"
          ></app-action-button>
        </app-message>
      </app-dropdown>
    </ng-template>

    <ng-template #tradingSuspension let-currency>
      <app-dropdown
        *ngIf="tradingSuspensionFTM && currency.name === 'FTM'"
        [popoverPosition]="'topStart'"
        [popoverClass]="'md:min-w-[382px]'"
      >
        <button ribbon dropdown-trigger>
          <svg-icon src="/assets/icons/12/info-circle.svg" ribbon-icon-left></svg-icon>
          {{ 'currencies.trading-suspension.label' | translate }}
        </button>
        <app-message
          [type]="'warning'"
          [showMessage]="true"
          [compact]="true"
          [title]="'currencies.trading-suspension.title' | translate"
          [message]="'currencies.trading-suspension.message' | translate"
        >
          <app-action-button
            [facet]="'secondary'"
            [size]="'small'"
            [label]="'shared.common.close' | translate"
          ></app-action-button>
        </app-message>
      </app-dropdown>
    </ng-template>

    <app-currencies-show-more-button
      *ngIf="!(searchTermSubject | async)"
      class="mt-7"
      [currencies$]="balances$"
      [currenciesLimited$]="balancesLimited$"
      (click)="showAllBalances()"
    ></app-currencies-show-more-button>
  </app-base-modal-body>
</app-base-modal>
