import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import { Currencies } from '@app/shared/store/currencies/currencies-facade.service';
import { AppCurrencyFloorPipe } from '@app/shared/pipes/app-floor.pipe';
import { LanguageService } from '@app/shared/services/language.service';
import { TooltipComponent } from '@app/shared/components/tooltip/tooltip.component';
import { TooltipPosition } from '@app/shared/components/tooltip/tooltip.enum';

const CURRENCY_PRICE_FORMAT_REGEX = /^(\d+)([.,])?(\d+)?$/;

@Component({
  selector: 'app-currency-value',
  standalone: true,
  imports: [CommonModule, TooltipComponent],
  templateUrl: './currency-value.component.html',
  host: { class: 'font-[inherit]' },
})
export class CurrencyValueComponent implements OnInit, OnDestroy, OnChanges {
  @Input() value: number | null | undefined = 0;
  @Input() currency: string = '';
  @Input() hideCurrency: boolean = false;
  @Input() tooltipPosition: TooltipPosition = TooltipPosition.left;
  @Input() tooltipUsePopover = true;
  @Input() tooltipClass?: string;

  public value$: Observable<{
    valueFormatted: string;
    valueParts?: {
      integerPart: string;
      decimalSeparator: string;
      leadingZerosCount?: number;
      fractionalPart: string;
    };
  } | null> = of();
  public tooltipText: string | null = null;

  private valueSubject = new BehaviorSubject<number | null | undefined>(this.value);
  private currenciesRounding: { [key: string]: number } = {};
  private currenciesRoundingSubscription?: Subscription;
  private currencyFormatter: AppCurrencyFloorPipe;
  private minPrecision = 1;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private languageService: LanguageService,
    private currenciesFacade: Currencies,
  ) {
    this.currencyFormatter = new AppCurrencyFloorPipe(this.changeDetector, this.languageService, this.currenciesFacade);

    this.currenciesRoundingSubscription = this.currenciesFacade.currenciesRounding$.subscribe((currenciesRounding) => {
      this.currenciesRounding = currenciesRounding;
    });
  }

  ngOnInit() {
    this.value$ = this.valueSubject.pipe(
      withLatestFrom(this.currenciesFacade.currenciesRounding$),
      map(([value, currenciesRounding]) => {
        if (typeof value !== 'number') {
          return null;
        }

        const valueBN = new BigNumber(value);
        const decimalPlaces = this.currenciesRounding[this.currency] || 2;
        const valueFlooredBN = valueBN.decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN);

        if (valueFlooredBN.isZero() && !valueBN.isZero()) {
          const [_, fractionalPart] = valueBN.toString().split('.');
          const leadingZerosCount = fractionalPart.match(/^0+/)?.[0].length ?? 0;

          const valueFormatted = this.formatCurrency(valueBN.toNumber());
          this.tooltipText = this.formatCurrency(valueBN.toNumber());

          const [__, integerPart, decimalSeparator] = valueFormatted.split(CURRENCY_PRICE_FORMAT_REGEX);
          const fractionalPartWithoutLeadingZerosFloored = fractionalPart
            .replace(/^0+/, '')
            .substring(0, leadingZerosCount);

          return {
            valueFormatted: valueBN.toString(),
            valueParts: {
              integerPart,
              decimalSeparator,
              leadingZerosCount: leadingZerosCount > 2 ? leadingZerosCount : 0,
              fractionalPart:
                leadingZerosCount > 2
                  ? fractionalPartWithoutLeadingZerosFloored
                  : `${''.padStart(2, '0')}${fractionalPartWithoutLeadingZerosFloored}`,
            },
          };
        }

        return {
          valueFormatted: this.formatCurrency(valueBN.toNumber()),
        };
      }),
    );
  }

  ngOnDestroy() {
    this.currenciesRoundingSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.valueSubject.next(changes.value.currentValue);
    }
  }

  private formatCurrency(value: number) {
    return this.currencyFormatter.transformNumber(value, this.currency, this.minPrecision);
  }
}
