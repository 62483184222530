import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LocalizeRouterPipe } from '@gilsdav/ngx-translate-router';
import { FooterColumn, FooterColumnWithIcons, FooterLink } from '@app/shared/api/footer.api';
import {
  LanguageSwitchComponent,
  LanguageSwitchTypeEnum,
} from '@app/shared/components/language-switch/language-switch.component';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { GuiParamsDto } from '@app/generated/models/gui-params-dto';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { ThemeImageComponent } from '@app/shared/components/theme-image/theme-image.component';
import { FooterLinkComponent } from '@app/shared/components/footer/footer-link/footer-link.component';
import { FooterIconLinkComponent } from '@app/shared/components/footer/footer-icon-link/footer-icon-link.component';

const SIGN_UP_LINK: FooterLink = { text: 'shared.footer.signup', routerLink: '/sign-up', link: null };
const AFFILIATE_LINK_PUBLIC: FooterLink = {
  text: 'shared.footer.coinmate.links.affiliate',
  routerLink: null,
  link: 'https://coinmate.io/cs/affiliate-program/',
  openInNewTab: true,
};
const AFFILIATE_LINK: FooterLink = {
  text: 'shared.footer.coinmate.links.affiliate',
  routerLink: '/affiliate',
  link: null,
};

const LINKS_PRODUCT = [
  SIGN_UP_LINK,
  { text: 'shared.footer.product.links.buy-sell', routerLink: '/quick-trade', link: null },
  { text: 'shared.footer.product.links.trade', routerLink: '/trade', link: null },
  { text: 'shared.footer.product.links.recurring-buy', routerLink: '/recurring-buy', link: null },
  {
    text: 'shared.footer.product.links.otc-desk',
    routerLink: null,
    lang: ['cs'],
    link: 'https://coinmate.io/cz/otc-desk/',
  },
  { text: 'shared.footer.product.links.api-services', routerLink: '/developers', link: null },
  // { text: 'shared.footer.product.links.institutions', routerLink: '/institutions', link: null },
  { text: 'shared.footer.product.links.fees', routerLink: '/fees', link: null },
  { text: 'shared.footer.product.links.cryptocurrencies', routerLink: '/prices', link: null },
  {
    text: 'shared.footer.product.links.tradingview',
    routerLink: null,
    lang: ['cs', 'en'],
    link: 'https://coinmate.io/cz/tradingview/',
  },
];

const LINKS_COINMATE = [
  { text: 'shared.footer.coinmate.links.our-story', routerLink: '/our-story', link: null },
  {
    text: 'shared.footer.coinmate.links.career',
    routerLink: null,
    lang: ['cs'],
    link: 'https://www.coinmate.io/cz/kariera',
  },
  { text: 'shared.footer.coinmate.links.legal-privacy', routerLink: '/legal', link: null },
  { text: 'shared.footer.coinmate.links.whistleblowing', routerLink: '/whistleblowing', link: null },
  {
    text: 'shared.footer.coinmate.links.bug-bounty',
    routerLink: null,
    link: 'https://hackerone.com/coinmate',
    openInNewTab: true,
  },
  // AFFILIATE_LINK_PUBLIC,
  // TODO: Replace the link with a public link
  AFFILIATE_LINK,
  { text: 'shared.footer.coinmate.links.questions-answers', routerLink: '/faq', link: null },
  { text: 'shared.footer.coinmate.links.support', routerLink: '/support', link: null },
];

const LINKS_COMMUNITY = [
  {
    text: 'shared.footer.community.links.discord',
    icon: '36/discord.svg',
    routerLink: null,
    lang: ['cs'],
    link: 'https://www.coinmate.io/cz/discord/',
    openInNewTab: true,
  },
  {
    text: 'shared.footer.community.links.facebook',
    icon: '36/facebook.svg',
    routerLink: null,
    lang: ['cs'],
    link: 'https://www.facebook.com/Coinmate/',
    openInNewTab: true,
  },
  {
    text: 'shared.footer.community.links.twitter',
    icon: '36/twitter.svg',
    routerLink: null,
    lang: ['cs'],
    link: 'https://x.com/CoinmateBT/',
    openInNewTab: true,
  },
  {
    text: 'shared.footer.community.links.linkedin',
    icon: '36/linkedin.svg',
    routerLink: null,
    lang: ['cs'],
    link: 'https://www.linkedin.com/company/coinmate/',
    openInNewTab: true,
  },
  {
    text: 'shared.footer.community.links.youtube',
    icon: '36/youtube.svg',
    routerLink: null,
    lang: ['cs'],
    link: 'https://www.youtube.com/@coinmate_io/',
    openInNewTab: true,
  },
  {
    text: 'shared.footer.community.links.spotify',
    icon: '36/spotify.svg',
    routerLink: null,
    lang: ['cs'],
    link: 'https://open.spotify.com/show/79HgoCzjg45wnClH4AtDoc?si=63de90f728c842d0',
    openInNewTab: true,
  },
];

const LINKS_EDUCATION = [
  {
    text: 'shared.footer.education.links.academy',
    routerLink: null,
    lang: ['cs'],
    link: 'https://coinmate.io/cz/krypto-akademie/',
  },
  {
    text: 'shared.footer.education.links.articles',
    routerLink: null,
    lang: ['cs'],
    link: 'https://coinmate.io/cz/krypto-clanky/',
  },
  {
    text: 'shared.footer.education.links.glossary',
    routerLink: null,
    lang: ['cs'],
    link: 'https://coinmate.io/cz/krypto-slovnik/',
  },
];

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    ThemeImageComponent,
    FooterLinkComponent,
    FooterIconLinkComponent,
    LocalizeRouterPipe,
    LanguageSwitchComponent,
    TranslatePipe,
  ],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() isFullWidth = false;

  lang = '';
  languageSwitchTypeEnum = LanguageSwitchTypeEnum;
  isAppDownloadLinkVisible = false;

  product: FooterColumn = {
    title: 'shared.footer.product.title',
    links: LINKS_PRODUCT.concat(),
  };

  coinmate: FooterColumn = {
    title: 'shared.footer.coinmate.title',
    links: LINKS_COINMATE.concat(),
  };

  community: FooterColumnWithIcons = {
    title: 'shared.footer.community.title',
    caption: 'shared.footer.community.caption',
    links: LINKS_COMMUNITY.concat(),
  };

  education: FooterColumn = {
    title: 'shared.footer.education.title',
    links: LINKS_EDUCATION.concat(),
  };

  currentVersion$!: Observable<string | undefined>;
  appState = 'operational';

  private unsubscribe$ = new Subject<void>();

  constructor(private guiParams: GuiParams, private translateService: TranslateService) {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe((val) => (this.lang = val.lang));
  }

  ngOnInit() {
    this.currentVersion$ = this.guiParams.guiParams$.pipe(
      map((guiParams: GuiParamsDto) => (guiParams.versionDisplayed ? guiParams.version : undefined)),
    );

    this.guiParams.loggedIn$.subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        const signUpLinkPosition = this.product.links.indexOf(SIGN_UP_LINK);
        if (signUpLinkPosition >= 0) {
          this.product.links.splice(signUpLinkPosition, 1);
        }
        // TODO: Replace the link with a public link
        /* const affiliateLinkPosition = this.coinmate.links.indexOf(AFFILIATE_LINK_PUBLIC);
        if (affiliateLinkPosition >= 0) {
          this.coinmate.links.splice(affiliateLinkPosition, 1, AFFILIATE_LINK);
        } */
      } else {
        const signUpLinkPosition = this.product.links.indexOf(SIGN_UP_LINK);
        if (signUpLinkPosition === -1) {
          this.product.links.splice(signUpLinkPosition, 1, SIGN_UP_LINK);
        }
        // TODO: Replace the link with a public link
        /*const affiliateLinkPosition = this.coinmate.links.indexOf(AFFILIATE_LINK);
        if (affiliateLinkPosition >= 0) {
          this.coinmate.links.splice(affiliateLinkPosition, 1, AFFILIATE_LINK_PUBLIC);
        }*/
      }
    });

    this.guiParams.appState$.subscribe((appState: string) => {
      this.appState = appState || 'operational';
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCurrentYear(): number {
    const date = new Date();
    return date.getFullYear();
  }
}
