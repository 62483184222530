<footer
  class="cm-footer"
  [ngClass]="{
    'cm-footer-full-width': isFullWidth
  }"
>
  <div class="cm-footer-menu">
    <div class="cm-footer-container">
      <div class="cm-footer-columns">
        <div class="cm-footer-column">
          <div class="cm-footer-logo-wrap" [ngClass]="{
            'flex flex-col items-center': appState === 'verification'
          }">
            <a [routerLink]="['/' | localize]" class="cm-footer-logo">
              <app-theme-image srcLight="/assets/cm-logo.svg" srcDark="/assets/cm-logo-dark.svg" alt="Coinmate" />
            </a>
            <p *ngIf="currentVersion$ | async as currentVersion" class="text-xs text-red mt-12px lg:mt-36px">
              {{ currentVersion }}
            </p>
          </div >
          <div *ngIf="lang === 'cs' && isAppDownloadLinkVisible && appState !== 'verification'" class="cm-footer-app-download">
            <p class="cm-footer-caption">{{ 'shared.footer.app-download.caption' | translate }}</p>
            <div class="cm-footer-app-download-images">
              <a href="https://www.apple.com/cz/app-store/" target="_blank" rel="noopener noreferrer">
                <img src="/assets/img/app-store.png" alt="App Store" />
              </a>
              <a href="https://play.google.com/store/" target="_blank" rel="noopener noreferrer">
                <img src="/assets/img/google-play.png" alt="Google Play" />
              </a>
            </div>
          </div>
        </div>
        <ng-container *ngIf="appState !== 'verification'">
          <app-footer-link class="cm-footer-column" [columnData]="product" />
          <app-footer-link class="cm-footer-column" [columnData]="coinmate" />
          <ng-container *ngIf="lang === 'cs'">
            <div class="cm-footer-column">
              <app-footer-icon-link [columnData]="community" />
            </div>
            <div class="cm-footer-column">
              <app-footer-link [columnData]="education" />
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div *ngIf="appState !== 'verification'" class="cm-footer-disclaimer" [innerHTML]="'shared.footer.disclaimer' | translate"></div>
    </div>
  </div>
  <div class="cm-footer-bar">
    <div class="cm-footer-container">
      <div class="cm-footer-columns">
        <div class="cm-footer-column">&copy; Coinmate a. s. 2014–{{ getCurrentYear() }}</div>
        <div class="cm-footer-column">
          <button onclick="Cookiebot.renew()">
            {{ 'shared.footer.cookies-settings' | translate }}
          </button>
        </div>
        <div class="cm-footer-column">
          <app-language-switch class="cm-footer-link" [type]="languageSwitchTypeEnum.footer" />
        </div>
      </div>
    </div>
  </div>
</footer>
