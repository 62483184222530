import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColorScheme, ColorSchemeService } from '@app/shared/services/color-scheme.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-theme-image',
  standalone: true,
  imports: [],
  templateUrl: './theme-image.component.html',
})
export class ThemeImageComponent implements OnInit, OnDestroy {
  @Input() srcLight: string = '';
  @Input() srcDark: string = '';
  @Input() alt: string = '';

  src = '';

  private unsubscribe$ = new Subject<void>();

  constructor(private colorSchemeService: ColorSchemeService) {}

  ngOnInit() {
    this.src = this.srcLight;
    this.colorSchemeService
      .getColorScheme()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((colorScheme) => {
        this.src = colorScheme === ColorScheme.LIGHT ? this.srcLight : this.srcDark;
      });

    // Preload images for smooth image switching
    [this.srcLight, this.srcDark]
      .filter((url) => url)
      .forEach((url) => {
        new Image().src = url;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
